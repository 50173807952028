<template>
  <div>
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Heartbeat Settings</div>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="4"> Minimum Time To Check Heartbeat ( In Minutes ) </v-col>
          <v-col cols="12" xs="12" sm="12" md="8">
            <v-select dense outlined class="field_height field_label_size FontSize maxWidth" :items="heartbeatItems" item-text="text" item-value="value"></v-select>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4"> Maximum Time To Check Heartbeat ( In Minutes ) </v-col>
          <v-col cols="12" xs="12" sm="12" md="8">
            <v-select dense outlined class="field_height field_label_size FontSize maxWidth" :items="heartbeatItems" item-text="text" item-value="value"></v-select>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4"></v-col>
          <v-col cols="12" xs="12" sm="12" md="8">
            <v-btn small color="primary">Update</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { GetHeartBeat } from "@/mixins/GetHeartBeat.js";
export default {
  mixins: [GetHeartBeat],
  data: () => ({
    heartbeatItems: [],
  }),
  mounted() {
    this.GetHeartBeatMethod();
  },
  methods: {},
};
</script>
